<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div class="page ld">
    <div class="logo">
      <img src="../assets/imgs/logo.png" alt="">
      <div class="progress">
        <div class="reProg" :style="'width:'+prog+ '%;'"></div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'App',
    components: {
    },
    data () {
      return {
      }
    },
    props:{
      prog:{
        default:13
      }
    }
  }
</script>
<style lang="less" scoped>
.ld{
  display: flex;
  justify-content: center;
  align-items: center;
}
  .ld .logo {
    width: 13%;
    margin-bottom: 60px;
  }
  .progress {
    margin-top: 20px;
    height: 5px;
    border-radius: 3px;
  }
  .reProg {
    background-color: #d13736;
    border-radius: 3px;
    height: 100%;
  }
  @media screen and (max-width: 768px){
    .ld .logo{
      width: 30%;
    }
  }
</style>

import Vue from 'vue'
import vuei18n from 'vue-i18n/dist/vue-i18n.esm';
import zh from './zh';
import en from './en';
Vue.use(vuei18n)
const i18n = new vuei18n({
  locale:'zh',
  messages:{
    'zh':{...zh},'en':{...en}
  },
  silentTranslationWarn:true
})
export default i18n
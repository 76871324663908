<template>

  <div class="pageBox">
    <Top @toList="toList"></Top>
    <div class="det-top">
      <div class="title">{{caseDet.title}}</div>

      <ul class="type">
        <li>{{caseDet.date}}</li>
        <li class="border" v-show="caseDet.tags&&caseDet.tags.length>0"></li>
        <li class="cate"> <span class="text">{{$t('det.type')}}：</span> {{caseDet.category}}</li>
        <li class="border" v-show="caseDet.tags&&caseDet.tags.length>0"></li>
        <li class="tag-desc" v-show="caseDet.tags&&caseDet.tags.length>0">
          <span v-for="it in caseDet.tags" :key="it.id">{{it.name}}</span>
        </li>
      </ul>

      <div class="pos-share">
        <div class="box">
          <img :src="caseDet.company_icon
" alt="">
          <span class="pos">{{caseDet.company}}</span>
        </div> <span class="share" @click="shareBox">
          <img src="../assets/imgs/icon.png" alt="">
          {{$t('det.share')}}</span>
        <div class="share-box" v-show="isShareBox">
          <div class="copy" @click="copyUrl">{{$t('det.copy_url')}}</div>
          <img :src="codeUrl" alt="">
        </div>
      </div>
    </div>
    <div class="det-rec">
      <div class="case-det">
        <ul class="cre">
          <li @click="$router.push('/')">{{$t('top.index')}} ></li>
          <li class="all-case-uli" @click="$router.push('/list')">{{$t('top.all_case')}} ></li>
          <li>{{caseDet.title}}</li>
        </ul>
        <div class="swiper-wrap">
          <div class="title">{{caseDet.title}}</div>
          <div class="swiper-container case-det-swiper">
            <!-- <div class="swiper-wrapper"> -->
            <!-- <div class="swiper-slide" v-for="it in spArr" :key="it.id">
              <video :src="it.video_url" controls></video>
            </div> -->
            <viewer :images="caseDetArr" class="viewer swiper-wrapper">
              <div class="swiper-slide" v-for="it in spArr" :key="it.id">
                <video :src="it.video_url" controls></video>
              </div>
              <div class="swiper-slide" v-for="it in caseDetArr" :key="it">
                <img :src="it" alt="">
              </div>

            </viewer>

            <!-- </div> -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
          <div class="swiper-right">
            <div class="bt-share">
              <div class="bt" @click="toDet">{{$t('det.online')}}</div>

              <div class="share" @click="shareBox">{{$t('det.share')}}</div>
              <div class="share-box" v-show="isShareBox">
                <div class="copy" @click="copyUrl">{{$t('det.copy_url')}}</div>
                <img :src="codeUrl" alt="">
              </div>
            </div>
            <div class="tag-box">
              <div class="pos">
                <div class="icon"><img :src="caseDet.company_icon
" alt=""> </div>
                <span>{{caseDet.company}}</span>
              </div>
              <div class="date">{{$t('det.date')}}: <strong>{{caseDet.date}}</strong></div>
              <div class="type">{{$t('det.type')}}: <strong>{{caseDet.category}}</strong></div>
              <div class="tag">{{$t('det.tag')}}
                <div class="tag-text">:</div>
                <div class="tag-desc">
                  <span v-for="it in caseDet.tags" :key="it.id">{{it.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="case-dec">
          <div class="title">{{$t('det.intro')}}</div>
          <div class="dec" v-html="caseDet.content">
          </div>
          <div class="bt" @click="toDet">{{$t('det.online')}}</div>
        </div>
      </div>
      <div class="rec-case">
        <p class="title">
          {{$t('rec_case')}}
        </p>
        <div class="rec-case-wrap">
          <div class="swiper-container rec-case-swiper" v-show="detRecoArr.length>0">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="it in detRecoArr" :key="it.id">

                <div class="one-case" @click="clickRec(it.id)">
                  <div class="tag" v-show="it.tags[0]">{{it.tags[0]?it.tags[0].name:''}}</div>

                  <div class="pic">
                    <img :src="it.banner" alt="">
                  </div>
                  <div class="desc">
                    <div class="tit">{{it.title}}</div>
                    <div class="type">{{it.category}}</div>
                    <div class="border"></div>
                    <div class="pos">{{it.company}}</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div class="next-bt" @click="changeSwiper"></div>
        </div>
      </div>
    </div>
    <Bot></Bot>
  </div>
</template>

<script>
  import Copy from 'copy-to-clipboard';

  import Top from '../components/Top'
  import Bot from '../components/Bot'
  import Swiper from 'swiper';
  import $ from 'jquery';
  import qrCode from 'qrcode';
  import Viewer from 'v-viewer'
  import Vue from 'vue';

  import 'viewerjs/dist/viewer.css'


  // import socialShare from '../assets/share/js/social-share.min.js';
  import { Loading, Message } from 'element-ui';
  Vue.use(Viewer, {

    defaultOptions: {

      zIndex: 1,

      inline: false,

      button: true,

      navbar: false,

      title: false,

      toolbar: false,

      tooltip: true,

      movable: true,

      zoomable: true,

      rotatable: false,

      scalable: false,

      transition: true,

      fullscreen: true,

      keyboard: false,

      loop: false,

    }

  })
  export default {
    components: {
      Top, Bot
    }, watch: {
      '$i18n.locale': function () {
        this.getDet()
      }
    },

    created () {
      this.getDet()
      // console.log(this.$route.params);
      this.$watch(
        () => this.$route.params,
        () => {
          // 对路由变化做出响应...
          this.getDet()
        }
      )
      qrCode.toDataURL(location.href).then((code) => {
        // console.log(code);
        this.codeUrl = code
      });
      // console.log(Copy);
    },
    mounted () {
      // $(window).scroll(function () {
      // })
      $('html,body').scrollTop(0)
      this.caseDetSwiper = new Swiper('.case-det-swiper', {
        observer: true,
        observeParents: true,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on:{
          slideChangeTransitionEnd:function(){
            if(this.slides[this.previousIndex].querySelector('video')){
              this.slides[this.previousIndex].querySelector('video').pause()
            }
          }
        }
      })
      this.recCaseSwiper = new Swiper('.rec-case-swiper', {
        // slidesPerColumn: 2,
        // autoplay:1000,
        pagination: { el: this.$ppo.isMobile() ? '.swiper-pagination' : '' },
        // // speed:300,//切换速度，即slider自动滑动开始到结束的时间（单位ms），也是触摸滑动时释放至贴合的时间。
        slidesPerView: this.$ppo.isMobile() ? '1' : 'auto',
        observer: true,
        observeParents: true,

        spaceBetween: 10,
        // slidesPerColumnFill: 'row'
      })
    },
    methods: {

      toDet () {
        location.href = this.caseDet.url
        console.log();
      },
      shareBox () {
        this.isShareBox = !this.isShareBox;
      },
      copyUrl () {
        Copy(location.href)
        Message.success({
          "message": this.$t("det.copy_success")
        })
      },
      clickRec (e) {
        this.$router.push('/detail/' + e);
        $('html,body').scrollTop(0)
      },
      changeSwiper () {

        this.recCaseSwiper.slideNext()
      },
      getDet () {
        let loadingInstance = Loading.service({
          lock: true, text: this.$t('list.get_more'), background: 'transparent', customClass: 'toast'
        });
        this.$request.getDet({
          url: `/api/v1/${this.$i18n.locale}/case/${this.$route.params.case}`
        }).then((res) => {
          loadingInstance.close()
          let casedet = res.data.case
          let { recommend } = res.data
          this.spArr = casedet.videos
          this.caseDet = casedet
          this.caseDetArr = casedet.carousel
          this.detRecoArr = recommend
        })
        // this.$request.getDetReco({
      },
      toList (e) {
        this.$router.push({ name: 'List', params: { keyword: e } })
      },
    },
    data () {
      return {
        isShareBox: false,
        spArr: [],
        caseDet: {},
        caseDetArr: [],
        detRecoArr: [],
        caseDetSwiper: null,
        recCaseSwiper: null,
        codeUrl: '',
      }
    }
  }
</script>
<style lang="less" scoped>
  :deep(.swiper-container) {
    .swiper-button-prev,
    .swiper-button-next {
      width: 0.3rem;
      height: 0.3rem;
      background-color: rgba(0, 0, 0, 0.3);
      background-position: center;
      margin-top: -0.15rem;
      border-radius: 50%;
      background-size: 60% auto;
    }
    .swiper-pagination-bullet-active {
      background: #d13736;
    }
    .swiper-button-prev {
      background-image: url(../assets/imgs/left-ar.png);
    }
    .swiper-button-next {
      background-image: url(../assets/imgs/right-ar.png);
    }
  }
  .pageBox {
    min-height: 100%;
    background-color: #f5f5f5;
  }
  .det-rec {
    min-height: calc(100vh - 1.5rem);
  }
  .det-top {
    display: none;
  }
  .case-det {
    width: 13.3rem;
    background-color: #fff;
    // text-align: center;
    // box-sizing: border-box;
    padding: 0.5rem 0.6rem;
    // border: 1px dotted;
    margin: 0.6rem auto 0;
    .cre {
      li {
        cursor: pointer;
        display: inline-block;
      }
    }
    .swiper-wrap {
      position: relative;
      .case-det-swiper {
        margin: 0;
        width: 7.8rem;
        height: 5.2rem;
        .swiper-slide {
          text-align: center;
          touch-action: none;
          // border: 1px dotted;
          margin: 0;
          box-sizing: border-box;
          img {
            height: 100%;
          }
        }
      }
      video {
        // object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .title {
        font-size: 0.36rem;
        font-weight: bold;
        margin: 0.37rem 0 0.3rem;
        color: #000;
      }
      .swiper-right {
        width: 4rem;
        position: absolute;
        right: 0;
        top: 0.81rem;
        .bt-share {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .share-box {
            width: 1rem;
            position: absolute;
            border: 1px solid;
            right: 0;
            top: 0.56rem;
            // display: none;
          }
          &::after {
            display: none;
          }
        }
        .bt {
          padding: 0.1rem 1.4rem;
          background-color: #d13736;
          font-size: 0.13rem;
          color: #fff;
          // border: 1px solid #ccc;
          white-space: nowrap;
          border-radius: 3px;
        }
        .share {
          font-size: 0.13rem;
          background: url(../assets/imgs/icon.png) center top/0.18rem no-repeat;
          white-space: nowrap;
          padding: 0.2rem 0.06rem 0;
          border: 1px solid #ccc;
          border-radius: 3px;
          line-height: 1;
          height: 100%;
          display: inline-block;
        }
        .tag-box {
          padding: 0.2rem 0.37rem;
          // border: 1px solid #ccc;
          border-radius: 3px;
          strong {
            color: #000;
          }
          .pos {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 0.16rem;
            // text-align: center;
            span {
              color: #000;
            }
            .icon {
              width: 0.5rem;
              height: 0.5rem;
              margin-right: 0.1rem;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
              }
            }
          }
          .type {
            margin: 0.16rem 0;
          }
          .tag,
          .tag-desc {
            display: flex;
            flex-wrap: wrap;
            span {
              padding: 0.06rem 0.1rem;
              border: 1px solid #ccc;
              border-radius: 3px;
              margin: 0 0.1rem 0.1rem 0;
            }
          }
        }
      }
    }
    .case-dec {
      // width: 7.8rem;
      // border: 1px dotted;
      // background-color: #ccc;
      .bt {
        display: none;
      }
      .dec {
        font-size: 0.27rem;
        line-height: 2;
      }
      .title {
        position: relative;
        font-size: 0.26rem;
        color: #000;
        margin: 0.37rem 0;
        &::after {
          width: 1em;
          position: absolute;
          left: 0;
          bottom: -0.1rem;
          content: "";
          border-top: 3px solid #ccc;
          visibility: visible;
        }
      }
    }
  }
  .rec-case {
    width: 13.3rem;
    margin: 0 auto;
    // border: 1px dotted #ccc;
    padding: 0.6rem 0 1rem;
    .title {
      margin-bottom: 0.25rem;
      font-size: 0.24rem;
      color: #000;
    }
    .rec-case-wrap {
      position: relative;
      .rec-case-swiper {
        min-height: 4.2rem;
        .swiper-slide {
          width: 4.4rem;
          // border: 1px dotted #ccc;
          height: 100%;
        }
      }
      .next-bt {
        width: 0.47rem;
        height: 0.47rem;
        position: absolute;
        top: 50%;
        right: -0.1rem;
        transform: translate(100%, -50%);
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        background: #fff url(../assets/imgs/right-ar-color.png) center/60% auto
          no-repeat;
      }
    }
  }
  .one-case {
    background-color: #fff;
    width: 100%;
    height: 100%;
    .tag {
      padding: 0 0.17rem;
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      color: #eee;
    }
    .pic {
      height: 2.7rem;
      background: url(/images/dragontrailh5/pc/test.png) center/cover no-repeat;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .desc {
      padding: 0.2rem;
      // border: 1px dotted;
      .tit {
        font-size: 0.16rem;
        font-weight: bold;
        color: #000;
      }
      .type {
        color: #ccc;
      }
      .border {
        margin: 0.16rem 0 0.26rem;
        border-top: 1px solid #ccc;
      }
      .pos {
      }
    }
  }
  @media screen and (max-width: 768px) {
    .case-det {
      width: 100%;
      margin: 0;
      padding: 0.5rem 0.3rem;
      .swiper-wrap {
        width: 100%;
        .title {
          display: none;
        }
        .case-det-swiper {
          width: 100%;
        }
      }
      .case-dec {
        .bt {
          font-size: 0.3rem;
          display: block;
          color: #fff;
          background-color: #d13736;
          margin: 0.7rem auto 0;
          width: 3rem;
          text-align: center;
          padding: 0.23rem 0;
          border-radius: 6px;
        }
        width: 100%;
        .title {
          font-size: 0.36rem;
        }
      }
    }
    .swiper-right {
      display: none;
    }
    .cre {
      display: none;
    }

    .rec-case {
      width: 100%;
      padding: 0.6rem 0.3rem;
      .title {
        font-size: 0.36rem;
        font-weight: bold;
      }
      .rec-case-wrap {
        .rec-case-swiper {
          height: 6rem;

          .swiper-slide {
            height: 5.3rem;
          }
        }
        .one-case {
          .pic {
            height: 3.7rem;
          }
        }

        .one-case {
          overflow: hidden;
          border-radius: 5px;
          background-color: #fff;

          position: relative;
          .tag {
            padding: 0.06rem 0.16rem;
            color: #eee;
            position: absolute;
            top: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 0 0 0 5px;
          }
          .pic {
            height: 4rem;
            // background: url(/images/dragontrailh5/pc/test.png) center/cover
            //   no-repeat;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .desc {
            padding: 0.2rem;
            // border: 1px dotted;
            .tit {
              font-size: 0.33rem;
              font-weight: bold;
              color: #000;
            }
            .type {
              color: #ccc;
              font-size: 0.22rem;
            }
            .border,
            .pos {
              display: none;
            }
          }
        }
      }
      .next-bt {
        display: none;
      }
    }
    .det-top {
      display: block;
      padding: 0.3rem;
      .title {
        font-size: 0.36rem;
        font-weight: bold;
      }
      .type {
        display: flex;
        margin: 0.3rem 0 0.16rem;
        justify-content: space-between;
        font-size: 0.22rem;
        .border {
          border-right: 1px solid #ccc;
        }
        .cate {
          span {
            color: #aaa;
          }
          // padding: 0 0.3rem;
        }
        .tag-desc {
          color: #aaa;
          span {
            border: 1px solid #ccc;
            padding: 0 0.1rem;
            display: inline-block;
            margin-right: 0.03rem;
          }
        }
      }

      .pos-share {
        &::after {
          display: none;
        }
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .share-box {
          position: absolute;
          top: 1rem;
          right: 0;
          z-index: 3;
        }
        .box {
          display: flex;
          align-items: center;
          img {
            width: 1rem;
            height: 1rem;
            object-fit: contain;
            border-radius: 50%;
          }
          .pos {
            font-size: 0.3rem;
            margin-left: 0.3rem;
          }
        }
        .share {
          display: flex;
          align-items: center;
          border-radius: 3px;
          border: 1px solid #ccc;
          padding: 0.03rem 0.06rem;
          img {
            width: 0.3rem;
            margin-right: 0.07rem;
          }
        }
      }
    }
  }
</style>

const i18n = require('./i18n');
// 项目配置
// console.log(i18n.default)
const isProd = (process.env.NODE_ENV === 'production');
const hosts = {
    dev: '/api/v1/'+i18n.default.locale,
    prod: '/api/v1/'+i18n.default.locale,
};
const api = {
    // 活动信息
    getRec: {
        method: 'GET',
        url: '/excellent'
    },
    // getDetReco: {
    //     method: 'GET',
    //     url: '/recommend'
    // },
    getDet:{
        method: 'GET',
        url: '/case'
    },
    getList: {
        method: 'GET',
        url: '/case'
    },
    getCon: {
        method: 'GET',
        url: '/condition'
    },
};

module.exports = {
    env: process.env.NODE_ENV,
    isProd: isProd,
    hosts: isProd ? hosts.prod : hosts.dev,
    api: api,
};

import Vue from 'vue';
import Router from 'vue-router';
import Index from './page/Index';

import Detail from './page/Detail';
import List from './page/List';
// import { isProd } from './utils/Config';
// import ppo from 'ppo';

Vue.use(Router);










const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
    // mode:'history',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/detail/:case',
            name: 'Detail',
            component: Detail
        },
        {
            path: '/list',
            name: 'List',
            component: List,
            meta: {
                auth: true,
                // 此处增加属性
                keepAlive: true,
                // 此处增加属性END
              }
        }, {
            path: '*',
            redirect:'/'
        },
    ]
});

// router.beforeEach((to, from, next) => {
//     if (ppo.isMobile()) {
//         if (ppo.isWeixin() || !isProd) {
//             if (to.path === '/') {
//                 next();
//             } else {
//                 next('/');
//             }
//         } else {
//             if (to.path === '/unWechat') {
//                 next();
//             } else {
//                 next('/unWechat');
//             }
//         }
//     } else {
//         if (to.path === '/desktop') {
//             next();
//         } else {
//             next('/desktop');
//         }
//     }
// });

export default router;

module.exports = {
  case_count: 'Cases',
  case_brand: 'Co-brands',
  all: 'All',
  case_country: 'Regional states',
  title: 'Case Library',
  partner: 'Partners',
  rec_case: 'Recommend case',
  cooperation: 'Business Cooperation',
  social: 'Social Media',
  index_title: '旅游数字营销专家',
  hoted_search: 'Hot Search',
  list: {
      tag_selected: 'Filter',
      no_kd_tip: 'Please input keywords',
      get_more: 'Loading...',
      no_date: 'Finish！',
      search_results: 'The search results of ',
      re_input: "Didn't conform to the case of the keywords, please re-enter or ",
      go_back: 'back Homepage'
  },
  top: {
      index: 'Home',
      all_case: 'Case',
      placeholder: 'Search for related keywords',
      search: 'Search'
  },
  det: {
      date: 'Date',
      type: 'Type',
      tag: 'Tag',
      copy_success:'',
      intro: 'Project Introduction',
      share: 'Share',
      copy_url: 'Copy URL',
      online: 'Link'
  }
}
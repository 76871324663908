// import Vue from 'vue';
// import { Notify } from 'vant';
import axios from 'axios';
import { hosts } from './Config';

// Vue.use(Notify);

// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('token');
    if (token) {
        config.headers['X-CSRF-TOKEN'] = token;
    }
    return config;

}, function (error) {
    return Promise.reject(error);
});

const request =  async function (options,that) {
    let headers = options.upimg ? {'Content-Type': 'multipart/form-data'}: {};
    const CancelToken =axios.CancelToken;
    const response = await axios({
        method: options.method || 'get',
        url: options.url || '/',
        data: options.data || '',
        params: options.params || '',
        headers,
        cancelToken:new CancelToken(function executor(c){
            if(that){
                that.cancel = c
            }
        })
    });
    if (response && response.data) {
        return response.data;
    }
};

const createApi = function (apiObj, prefix) {
    const backObj = {};
    Object.keys(apiObj).forEach(key => {
        const item  = apiObj[key];
        if (item.customUrl) {
            item.url = item.url+''
        } else {
            item.url = hosts + prefix + item.url;
        }
        backObj[key] = async function (options,that) {
            const data  = await request(Object.assign({}, item, options || {}),that);
            return data;
        };
    });
    return backObj;
};
// const allApis = createApi(api, '/peru_newyear2020');
// module.exports = Object.assign({ request: request }, allApis);
export default {
    request: request,
    createApi: createApi
};

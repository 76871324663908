import Vue from 'vue'
import App from './App.vue'
import './utils/reset'
// import vuei18n from 'vue-i18n/dist/vue-i18n.esm';
// import zh from './utils/zh';
// import en from './utils/en';
import i18n from './utils/i18n';

import 'swiper/dist/css/swiper.css'
import { api } from './utils/Config';
import request from './utils/Request';
import ppo from 'ppo';
import router from './router'
// sessionStorage.setItem('token', CSRF_TOKEN);
const _request = request.request;
const allApis = request.createApi(api, '');
const $request = Object.assign({ request: _request }, allApis);
// Vue.use(vuei18n)
Vue.config.productionTip = false;
Vue.prototype.$request = $request;
Vue.prototype.$ppo = ppo;
// console.log(ppo)

// const i18n = new vuei18n({

//   silentTranslationWarn:true
// })
router.beforeEach((to, from, next) => {
  // console.log(to, from, next);
  document.title=i18n.t('title')
  next()
})
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

<template>
  <header class="top-hd">
    <div class="box">
      <div class="logo">
        <img src="../assets/imgs/logo.png" alt="" @click="$router.push('/')">
      </div>
      <ul class="ul">
        <li @click="$router.push('/')">{{$t('top.index')}}</li>
        <li @click="getTot">{{$t('top.all_case')}}</li>
        <!-- <li>详情</li> -->
      </ul>
    </div>
    <div class="search">
      <div class="search-enter">
        <input type="text" :placeholder="$t('top.placeholder')" v-model.trim="keyword" @keyup.enter="toSearch">
        <img src="../assets/imgs/t-search.png" alt="" @click="toSearch"
          v-show="!($route.name === 'List' && $ppo.isMobile())">
      </div>

      <span class="change" @click="changeLang">{{this.$i18n.locale=='zh'?'Zh':'En'}}</span>
      <div class="top-ic" @click="category">
        <img src="../assets/imgs/mobile/top-icon.png" alt="">
      </div>
    </div>
    <ul class="cate-pop" v-show="showPop" >
      <li v-for="it in cateArr" :key="it.id" @click="goToList(it.id)" >{{it.name}}</li>
      <li class="changeLi" @click="changeLang">{{this.$i18n.locale=='zh'?'Zh':'En'}}</li>
    </ul>
  </header>



  
</template>
<script>
  export default {
    name: 'App',
    data () {
      return {
        keyword: '',cateArr:[],
        showPop:false,
      }
    },
    watch: {
      '$i18n.locale': function () {
        this.getCon()
      }
    },
    methods: {
      category(){
        this.showPop=!this.showPop
      },goToList (e) {
        this.showPop=false
        if (this.$route.name === "List") {
          this.$emit('clickpop',e)
        }else{
          this.$router.push(
            { name: 'List', params: { cate: e } }
          )

        }
        
      },
      getCon () {
        this.$request.getCon({
          url: `/api/v1/${this.$i18n.locale}/condition`
        }).then((data) => {
          this.cateArr = [{ id: '', icon: this.srcUrl, name: this.$i18n.t('all') },...data.data.category ]
        })
      },
      toSearch () {
        if (this.$route.name === "List") {
          this.$emit('search', this.keyword)
        } else {
          this.$emit('toList', this.keyword)
        }
      },
      changeLang () {
        this.$i18n.locale = this.$i18n.locale == 'zh' ? "en" : 'zh'
document.title=this.$i18n.t('title')
        this.showPop=false
      },
      getTot () {
        // if (this.$route.name === "Index") {
        // }
        if (this.$route.name === "List") {
          this.keyword = ''
          this.$emit('search', this.keyword)
        } else {
          this.$router.push('/list')
        }
      },
    },
    created () {
      this.getCon()
    }
  }
</script>
<style lang="less" scoped>
  .top-hd {
    position: relative;
    padding: 0.17rem 1rem;
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    .box {
      display: flex;
      align-items: center;
    }
    .change {
      line-height: 0.3rem;
      height: 0.37rem;
      width: 0.37rem;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 50%;
      text-align: center;
    }
    .search {
      display: flex;
      .search-enter {
        margin-right: 0.16rem;
        position: relative;
        img {
          cursor: pointer;
          width: 0.16rem;
          position: absolute;
          right: 0.13rem;
          top: 50%;
          transform: translateY(-50%);
        }
        input {
          width: 2.6rem;
          border: 1px solid #ccc;
          height: 100%;
          border-radius: 0.37rem;
          padding: 0 0.3rem 0 0.13rem;
        }
      }
      .top-ic,.cate-pop{display: none;}
    }
  }
  .top-hd .logo {
    width: 1.5rem;
    margin-right: 1.6rem;
  }
  .top-hd .ul li {
    cursor: pointer;
    color: #000;
    margin-left: 0.36rem;
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    .top-hd {
      .cate-pop{
        &::before{
          content: '';
          border: 0.1rem solid transparent;
          border-bottom-color:rgba(0,0,0,0.7);
          position: absolute;
          right: 0.1rem;
          transform: translateY(-100%);
          top: 0;
        }
        color: #ccc;
        position: absolute;
        right: 0.3rem;
        top: 1.3rem;
        z-index: 6;
        background-color: rgba(0,0,0,0.7);
        text-align: center;
        li{
          padding: 0.17rem 0.37rem;
          border-bottom: 1px solid #ccc;
        }
      }
      .logo{
        width: 2.3rem;
      }
      padding: 0.36rem 0.4rem;
      .box .ul,
      .search .search-enter input,
      .change {
        display: none;
      }
      .search {
        .top-ic {
          width: 0.5rem;
          display: flex;
          align-items: center;
        }
        .search-enter {
          margin-right: 0.43rem;
          img {
            position: static;
            width: 0.37rem;
            transform: translate(0);
          }
        }
      }
    }
  }
</style>

<template>
  <div class="pageBox">
    <Load v-if="loded" :prog="prog"></Load>
    <div class="box" v-show="!loded">
      <Top @toList="toList"></Top>
      <div class="case-total-pc">

        <div class="case-left">
          <div class="title">
            <!-- 优秀案例 -->{{$t("rec_case")}}
          </div>

          <ul class="case-ul">
            <li>{{case_number}} <span class="desc">{{$t('case_count')}}</span> </li>
            <li>{{case_brand}} <span class="desc">{{$t('case_brand')}}</span> </li>
            <li>{{case_country}} <span class="desc">{{$t('case_country')}}</span> </li>
          </ul>
          <div class="case-bt" @click="$router.push('list')">
            {{$t('top.all_case')}}
          </div>
        </div>
        <div class="swiper-container case-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in caseArr" :key="item.id"
              :style="'background-image:url('+item.banner+');'" @click="toDet(item)" @mouseenter="showBoxd(item.id)"
              @mouseleave="showBoxd(-1)">
              <div class="box" v-show="curBox==item.id">
                <div class="tit">{{item.title}}</div>
                <div class="tag" v-show="item.tags[0]">{{item.tags[0]?item.tags[0].name : ''}}</div>
                <div class="pos">{{item.company}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="case-tot-top">
        <div class="title">{{$t('index_title')}}</div>
        <ul class="case-ul">
          <li>{{case_number}} <span class="desc">{{$t('case_count')}}</span> </li>
          <li>{{case_brand}} <span class="desc">{{$t('case_brand')}}</span> </li>
          <li>{{case_country}} <span class="desc">{{$t('case_country')}}</span> </li>
        </ul>
        <div class="case-bt" @click="$router.push('list')">
          {{$t('top.all_case')}}
        </div>
      </div>
      <div class="case-tot">
        <ul class="cate">
          <li v-for="it in cateArr" :key="it.id" @click="goToList(it.id)">
            <img :src="it.icon" alt="">
            <div class="text">{{it.name}}</div>
          </li>
        </ul>
        <ul class="rec-case">
          <div class="title">{{$t('rec_case')}}</div>
          <div class="one-case" v-for="it in caseArr " :key="it.id" @click="$router.push('/detail/'+it.id)">
            <div class="tag" v-show="it.tags[0]">{{it.tags[0]?it.tags[0].name:''}}</div>

            <div class="pic">
              <img :src="it.banner" alt="">
            </div>
            <div class="desc">
              <div class="tit">{{it.title}}</div>
              <div class="type">{{it.category}}</div>
              <!-- <div class="pos">{{it.company}}</div> -->
            </div>
          </div>
        </ul>
      </div>
      <div class="bot-logo">
        <div class="bot-title">
          {{$t('partner')}}
        </div>
        
        <div class="swiper-container logo-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="it in $ppo.isMobile()? logoArrTop : logoArr" :key="it.id" :style="'background-image:url('+it.logo+')'">
            </div>
            <div class="swiper-slide" v-for="it in $ppo.isMobile()? logoArrTop : logoArr" :key="it.id+'/'"
              :style="'background-image:url('+it.logo+')'"></div>
          </div>
        </div>
        <div class="swiper-container logo-swiper-bt" v-show="$ppo.isMobile()">
          <div class="swiper-wrapper">
            
            <div class="swiper-slide" v-for="it in logoArrBot" :key="it.id+'/'"
              :style="'background-image:url('+it.logo+')'"></div>
          </div>
        </div>
      </div>

      <Bot v-show="$ppo.isMobile()"></Bot>
    </div>
  </div>

</template>
<script>
  import Swiper from 'swiper';

  import Load from '../components/Load.vue';
  import Top from '../components/Top.vue';

  import Bot from '../components/Bot.vue';
  export default {
    props: {
      msg: String
    },
    components: {
      Load, Top, Bot
    },
    watch: {
      '$i18n.locale': function () {
        this.getExcellent()
        this.getCon()
      },
      'logoArr': function () {
        this.$nextTick(function () {
          this.logoSwiperInit()
          this.logoBtSwiperInit()

        })
      }
    },

    data () {
      return {
        data: 0,
        curBox: -1,
        prog: 13,
        caseArr: [],
        loded: true,
        case_number: 0,
        case_country: 0,
        case_brand: 0,
        cateArr: [],
        srcUrl: require('../assets/imgs/mobile/all.png'),
        logoArr: [],
        logoArrTop: [],
        logoArrBot: [],
      }
    },
    async created () {
      let timer = setInterval(() => {
        this.prog++
        if (this.prog >= 83) {
          clearInterval(timer)
        }
      }, 100)
      this.getExcellent()
      this.getCon()
    },
    mounted () {
      this.swiperInit()

    },
    methods: {
      cancelQuest () {
        if (typeof this.cancel === 'function') {
          this.cancel('终止请求'); //取消请求
        }
      },
      goToList (e) {
        this.$router.push(
          { name: 'List', params: { cate: e } }
        )
      },
      async getExcellent () {
        let res = await this.$request.getRec({
          url: `/api/v1/${this.$i18n.locale}/excellent`
        })







        if (res.code == 0) {
          let { cases, setting, partners } = res.data
          this.caseArr = cases
          this.prog = 100
          this.logoArr = partners
          partners.forEach((el,tag) => {
            if(tag%2==0){
              this.logoArrBot.push(el)
            }else{
              this.logoArrTop.push(el)

            }
          });
          this.case_number = setting.case_count
          this.case_brand = setting.case_brand
          this.case_country = setting.case_country
          setTimeout(() => {
            this.loded = false
          }, 30)
        }
      },
      getCon () {
        this.$request.getCon({
          url: `/api/v1/${this.$i18n.locale}/condition`
        }).then((data) => {
          this.cateArr = [...data.data.category, { id: '', icon: this.srcUrl, name: this.$t('all') }]
        })
      },
      toList (e) {
        this.$router.push({ name: 'List', params: { keyword: e } })
      },
      showBoxd (e) {
        this.curBox = e
      },
      swiperInit () {
        new Swiper('.case-swiper', {
          slidesPerColumn: 2, mousewheel: true,
          slidesPerView: 3.3,
          spaceBetween: 10,
          slidesPerColumnFill: 'row',
          observer: true,
          observeParents: true,
        })
      }, 
      logoSwiperInit () {
        new Swiper('.logo-swiper', {
          autoplay: { delay: 0, disableOnInteraction: false, pauseOnMouseEnter: true, },
          speed: 1600,//切换速度，即slider自动滑动开始到结束的时间（单位ms），也是触摸滑动时释放至贴合的时间。
          slidesPerView: 'auto',
          observer: true,
          observeParents: true,
          spaceBetween: 8,
          loopedSlides: 10,
          centeredSlides: true,//设定为true时，active slide会居中，而不是默认状态下的居左。
          loop: true,allowTouchMove: false,
          on: {
            // click: function (e) {
            //   console.log(this, e);
            // }, 
            // setTranslate: function (e) {
            //   console.log(this, e);
            //   //自定义事件
            // },
          },
        })
      },
      logoBtSwiperInit () {
        new Swiper('.logo-swiper-bt', {
          autoplay: { delay: 0, disableOnInteraction: false, pauseOnMouseEnter: true, },
          speed: 1600,//切换速度，即slider自动滑动开始到结束的时间（单位ms），也是触摸滑动时释放至贴合的时间。
          slidesPerView: 'auto',
          observer: true,
          observeParents: true,
          spaceBetween: 8,
          loopedSlides: 10,
          centeredSlides: true,//设定为true时，active slide会居中，而不是默认状态下的居左。
          loop: true,allowTouchMove: false,
          on: {
            // click: function (e) {
            //   console.log(this, e);
            // }, 
            // setTranslate: function (e) {
            //   console.log(this, e);
            //   //自定义事件
            // },
          },
        })
      },
      toDet (et) {
        console.log(et);
        this.$router.push('detail/' + et.id)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
:deep(.logo-swiper-bt .swiper-wrapper) {
    transition-timing-function: linear;
  }
  :deep(.logo-swiper .swiper-wrapper) {
    transition-timing-function: linear;
  }
  .pageBox {
    padding-bottom: 1.37rem;
  }
  .case-total-pc {
    width: 16.8rem;
    // height: 7rem;
    padding: 0.6rem 0.37rem;
    background: linear-gradient(
      to right,
      #d13736 0%,
      #d13736 22.62%,
      #fff 22.62%,
      #fff 100%
    );
    margin: 0.3rem auto 0;
    display: flex;
    .case-left {
      color: #fff;
      position: relative;
      padding-right: 1rem;
    }
    .title {
      position: relative;
      font-size: 0.24rem;
      margin: 0.26rem 0 0.5rem;
      white-space: nowrap;
      &::after {
        content: "";
        left: 0;
        width: 60%;
        border-top: 3px solid #e38786;
        visibility: visible;
        position: absolute;
        bottom: -0.16rem;
      }
    }
    .case-ul {
      font-size: 0.38rem;
      & .desc {
        font-size: 0.16rem;
        display: block;
        line-height: 1;
      }
      li {
        margin: 0.2rem 0;
      }
    }
    .case-bt {
      border: 1px solid #e38786;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0.1rem 0.16rem;
      border-radius: 3px;
      cursor: pointer;
    }
    .case-swiper {
      height: 5.6rem;
      flex: 1;
      .swiper-slide {
        width: 100%;
        height: calc((100% - 8px) / 2);
        box-sizing: border-box;
        background: center/cover no-repeat;
        .box {
          position: relative;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          .tit,
          .tag,
          .pos {
            position: absolute;
            color: #fff;
            // border: 1px solid #ccc;
          }
          .tit {
            left: 0.1rem;
            top: 0.17rem;
            font-size: 0.17rem;
          }
          .tag {
            top: 0.17rem;
            right: 0.1rem;
            border-radius: 3px;
            padding: 0 0.1rem;
            border: 1px solid #ccc;
          }
          .pos {
            left: 0.1rem;
            bottom: 0.17rem;
          }
        }
        img {
          object-fit: cover;
        }
      }
    }
  }
  .case-tot-top,
  .case-tot {
    display: none;
  }
  .bot-logo {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    z-index: 3;
    padding: 0.1rem 1rem;
    background-color: #fff;
    .bot-title {
      color: #000;
      margin-bottom: 0.17rem;
      text-align: center;
      font-size: 0.22rem;
    }
  }
  .logo-swiper {
    .swiper-slide {
      width: 1.4rem;
      height: 0.6rem;
      background: #ccc center/contain no-repeat;
    }
  }

  @media screen and (max-width: 768px) {
    .pageBox {
      padding-bottom: 0;
    }
    .case-total-pc {
      display: none;
    }
    .case-tot-top {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .case-ul {
        display: flex;
        margin: 0.3rem 0 0.86rem;
        color: #d13736;
        font-size: 0.53rem;
        font-weight: bold;
        li {
          display: flex;
          flex-direction: column;
          &:nth-of-type(2) {
            padding: 0 0.3rem;
            position: relative;
            margin: 0 0.3rem;
            &::after,
            &::before {
              content: "";
              border-left: 1px solid #ccc;
              height: 60%;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
        }
        span {
          font-size: 0.3rem;
          font-weight: normal;
        }
      }
      .title {
        font-size: 0.4rem;
        font-weight: bold;
      }
      .case-bt {
        padding: 0.16rem 0.6rem;
        border-radius: 3px;
        background-color: #d13736;
        font-size:0.3rem; 
        color: #fff;
      }
    }
    .case-tot {
      background-color: #eee;
      display: block;
      .cate {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0.3rem 0;
        li {
          img {
            width: 0.7rem;
            height: 0.6rem;
          }
          text-align: center;
          width: 23%;
          .text {
            padding: 0.1rem 0 0.3rem;
            // border: 1px dotted;
            font-size: 0.27rem;
          }
        }
      }
      .rec-case {
        .title {
          font-size: 0.4rem;
          font-weight: bold;
          margin-bottom: 0.3rem;
          text-align: center;
        }
        .one-case {
          border-radius: 5px;
          overflow: hidden;
          margin: 0 auto 0.3rem;
          background-color: #fff;
          width: 7rem;
          position: relative;
          .tag {
            padding: 0.06rem 0.16rem;
            color: #eee;
            position: absolute;
            top: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 0 0 0 5px;
          }
          .pic {
            height: 4rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .desc {
            padding: 0.2rem;
            .tit {
              font-size: 0.33rem;
              font-weight: bold;
              color: #000;
            }
            .type {
              color: #ccc;
              font-size: 0.22rem;
            }
            .border {
              margin: 0.16rem 0 0.26rem;
              border-top: 1px solid #ccc;
            }
          }
        }
      }
    }
    .bot-logo {
      position: static;
      padding: 0.7rem 0.4rem 0.6rem;
      background: 0;
      .bot-title {
        font-size: 0.4rem;
        font-weight: bold;
        margin-bottom: 0.3rem;
        text-align: center;
      }
    }
    .logo-swiper,.logo-swiper-bt {
      margin-bottom: 0.3rem;
      .swiper-slide {
        width: 2.07rem;
      background: #ccc center/contain no-repeat;
        height: 1rem;
      }
    }
  }
</style>

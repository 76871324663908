<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div >

    <keep-alive>
      <!-- 里面是当需要缓存时   显示组件的 router-view-->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 外面是不需要缓存时 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
  // import Index from './page/Index.vue'

  export default {
    name: 'App',
    // components: {
    //   Index
    // }
  }
</script>
<style lang="less">
  @import "./assets/less/app.less";
</style>

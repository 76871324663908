<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div class="pageBox">
    <Top @search="search" ref="topBox" @clickpop="clickpop"></Top>
    <div class="list-top">
      <div class="title">{{$t('top.all_case')}}</div>

      <div class="search-bt">
        <el-input clearable class="input"
          :placeholder="$t('top.placeholder')"
          prefix-icon="el-icon-search"
          v-model.trim="kd">
        </el-input>
        <span class="bt" @click="toSearch">{{$t('top.search')}}</span>
        <!-- <input type="text" class="input" v-model.trim="kd"> -->

      </div>
      <div class="pop">
        <span class="text">{{$t('hoted_search')}}:</span>
        <span v-for="it in selectTags.filter((it)=>it.id)" :key="it.id" :class="{'pop-text':true, on:actTag===it.id}"
          @click="clickPop(it.name)">{{it.name}}</span>
      </div>

    </div>
    <div class="all-case-box">
      <div class="left-ul">
        <p class="all">{{$t('top.all_case')}}</p>

        <ul class="case-left-ul">
          <li v-for="it in selectType" :key="it.id" :class="{on:actCate===it.id}" @click="changeCate(it)">{{it.name}}
          </li>
        </ul>
        <p class="select">{{$t('list.tag_selected')}}</p>
        <span v-for="it in selectTags" :key="it.id" :class="{on:actTag===it.id}"
          @click="changeTags(it)">{{it.name}}</span>
      </div>
      <div class="case-box">
        <ul class="tabs-box">
          <li v-for="it in selectType" :key="it.id" :class="{on:actCate===it.id}" @click="changeCate(it)">{{it.name}}
          </li>
        </ul>
        <ul class="tags-box">
          <li v-for="it in selectTags" :key="it.id" :class="{on:actTag===it.id}"
            @click="changeTags(it)">{{it.name}}</li>
        </ul>
        <div class="re-tip" v-show="showTip">
          <p v-show="keyword && showTip">
            {{this.$i18n.locale!='zh'?$t('list.search_results'):''}}{{keyword}}{{this.$i18n.locale=='zh'?$t('list.search_results'):''}}
          </p>
          <div class="no-result" v-show="listArr.length==0">
            {{$t('list.re_input')}}<a href="/">{{$t('list.go_back')}}</a> 。
          </div>
        </div>
        <div class="one-case" v-for="it in listArr " :key="it.id" @click="toDet(it,$event)">
          <div class="tag" v-show="it.tags[0]">{{it.tags[0]?it.tags[0].name:''}}</div>
          <div class="pic">
            <img :src="it.banner" alt="">
          </div>
          <div class="desc">
            <div class="tit">{{it.title}}</div>
            <div class="type">{{it.category}}</div>
            <div class="border"></div>
            <div class="pos">{{it.company}}</div>
          </div>
          <!-- <img src="../assets/imgs/search.png" alt=""> -->
        </div>
        <div class="get-tip" v-show="showGetTip">{{$t('list.get_more')}}</div>
        <div class="page-box">
          <!-- <span v-for="it in totPage" :key="it" @click="page=it">{{it}}</span> -->
          <el-pagination background
            layout="prev, pager, next"

            :page-count="totPage"
            :pager-count="5"
            hide-on-single-page
            @current-change="currentChange"></el-pagination>
        </div>
      </div>
    </div>
    <Bot></Bot>
    <img src="../assets/imgs/to_top.png" alt="" class="to-top" @click="toTop" v-show="showTop">
  </div>
</template>
<script>
  import $ from 'jquery';
  import Vue from 'vue';
  import { Button, Select, Message, Pagination, Loading, Input } from 'element-ui';
  import Top from '../components/Top.vue';
  import Bot from '../components/Bot.vue';
  Vue.use(Button)
  Vue.use(Pagination)
  Vue.use(Select)
  Vue.use(Input)







  export default {
    name: 'List',
    components: {
      Top, Bot
    },
    watch: {
      '$i18n.locale': function () {
        this.resetArr()
        this.getList()
      },
      keyword: function () {
        this.resetArr()
        this.getList()
      },
      actCate: function () {
        this.getList()
      }, page: function () {
        this.getList()
      },
      actTag: function () {
        this.getList()
      },
    },
    data () {
      return {
        kd: '', pageCg: false, showGetTip: false,
        keyword: '', cate: '', tags: '', showTip: false,
        page: 1, limit: 9, totPage: 0,
        listArr: [],
        selectTags: [], pageTop: '',
        selectType: [],
        actCate: '',
        cancel: null,
        actTag: '', showTop: false
      }
    },
    created () {
      // this.kd = '游戏'
      if (this.$ppo.isMobile()) { this.limit = 30 }
      if (this.$route.params.keyword) {
        this.keyword = this.$route.params.keyword
      }
      this.pageTop = sessionStorage.getItem('top') * 1 || 0
      this.actCate = this.$route.params.cate ? this.$route.params.cate : ''
      if (!this.$route.params.cate) { this.getList() }
    },
    activated () {
      let that = this      
      $(window).scroll(function () {
        // if(!that.$ppo.isMobile()){
        //   console.log($('.left-ul')); 
        // }
        if (that.listArr.length == 0) {
          return
        } if (that.$ppo.isMobile()) {
          that.showTop = this.scrollY > 3000
          let scr = $('.case-box')[0].scrollHeight - this.scrollY
          if (scr < 600 && that.pageCg) {
            that.page++
            // console.log(that.page, '///')
            if (that.page > that.totPage) {
              that.page = that.totPage
              that.showGetTip = true
              setTimeout(function () { $('.get-tip').text(that.$t('list.no_date')) }, 30)
            }
            // console.log(that.page)
          }
        }
      })
    },
    deactivated () {
      $(window).unbind('scroll')
    },
    beforeDestroy () {
      $(window).unbind('scroll')
    },
    methods: {
      currentChange (cur) {
        console.log(cur)
        this.page = cur
      },
      cancelQuest () {
        if (typeof this.cancel === 'function') {
          this.cancel(); //取消请求
        }
      },
      getList () {
        this.cancelQuest()
        this.showGetTip = true
        this.showTip = false
        let loadingInstance;
        if (!this.$ppo.isMobile()) {
          loadingInstance = Loading.service({
            //   // target:'.case-box'
            lock: true, text: this.$t('list.get_more'), background: 'transparent', customClass: 'toast'
          });
        }
        this.pageCg = false
        this.$request.getList({
          url: `/api/v1/${this.$i18n.locale}/case`,
          params: { tags: this.actTag, kwd: this.keyword, cat: this.actCate, page: this.page, limit: this.limit }
        }, this).then((res) => {
          this.pageCg = true
          
          this.showGetTip = false
          if (this.$ppo.isMobile()) {
            this.listArr = this.listArr.concat(res.data)
            // console.log(this.listArr);
          } else {
            this.listArr = res.data
          }
          this.showTip = true
          if (loadingInstance) {
            loadingInstance.close()
          }
          this.selectType = [{ id: '', name: this.$t('all') }, ...res.condition.category]
          this.selectTags = [{ id: '', name: this.$t('all') }, ...res.condition.tag]
          this.totPage = res.page.total_page
        }).catch(() => {
          // console.log(err);
        })
      },
      toSearch () {
        if (!this.kd) { Message.warning(this.$t('list.no_kd_tip')) }
        // this.resetArr()
        this.keyword = this.kd
      },
      toTop () {
        $('html,body').scrollTop(0)
      },
      clickPop (e) {
        this.kd = e
      },
      search (e) {
        this.keyword = e
      },
      toDet (it, e) {
        sessionStorage.setItem('top', e.pageY)
        this.$router.push('/detail/' + it.id)
      },
      clickpop (e) {
        if (this.actCate === e) {
          return
        }
        this.resetArr()
        this.actCate = e
      },
      handleClick (tab, e) {
        console.log(tab, e)
      },
      changeCate (it) {
        if (this.actCate == it.id) {
          return
        }
        this.resetArr()
        this.page = 1
        this.actCate = it.id
      },
      resetArr () {
        if (this.$ppo.isMobile()) {
          $('.get-tip').text(this.$t('list.get_more'))
          this.listArr = []
        }
      },
      changeTags (it) {
        // if(this.showGetTip){
        //   return
        // }
        // console.log(this.actTag,"///",it.id)
        if (this.actTag == it.id) {
          return
        }
        this.resetArr()
        this.actTag = it.id
      }
    }
  }
</script>
<style lang="less">
  // @media screen and (max-width: 768px) {
  .el-loading-mask.is-fullscreen .el-loading-spinner {
    width: 3rem;
    margin-top: 0;
    border: 1px solid #ccc;
    background-color: rgba(0, 0, 0, 0.7);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    padding: 0.3rem 0;
    .el-loading-text {
      // color: #000;
    }
  }
  // }
</style>
<style lang="less" scoped>
  :deep(.el-pager) {
    li.active {
      background-color: #d13736 !important;
    }
    li {
      background-color: #eee !important;
    }
  }
  .pageBox {
    min-height: 100%;
    // background-color: #f5f5f5;
  }
  .list-top {
    display: none;
  }
  .all-case-box {
    justify-content:flex-end;
    background-color: #f8f8f6;
    padding: 1rem;
    display: flex;
    min-height: calc(100vh - 1.27rem);
    &::after {
      display: none;
    }
    .tags-box,
    .tabs-box {
      display: none;
    }
    .left-ul {
      width: 2.5rem;
      position: fixed;
      left: 1rem;
      top: 1.67rem;
      // border: 1px solid #ccc;
      .all {
        height: 0.6rem;
        line-height: 0.6rem;
        background: #fff url(../assets/imgs/book.png) 0.2rem center/0.3rem
          no-repeat;
        font-size: 0.26rem;
        font-weight: bold;
        text-indent: 0.6rem;
        border-radius: 3px;
      }
      .case-left-ul {
        margin: 0.1rem 0 0.37rem;
        border-radius: 3px;
        li {
          cursor: pointer;
          background-color: #fff;
          height: 0.6rem;
          padding-left: 0.23rem;
          line-height: 0.6rem;
          &.on {
            background-color: #d13736;
            color: #fff;
          }
        }
      }
      .select {
        margin-bottom: 0.13rem;
        font-size: 0.1rem;
      }
      span {
        cursor: pointer;
        border: 1px solid #ccc;
        padding: 0 0.1rem;
        white-space: nowrap;
        margin: 0 0.16rem 0.1rem 0;
        display: inline-block;
        &.on {
          background-color: #d13736;
          color: #fff;
        }
      }
    }
    .case-box {
      width: 13.7rem;
      display: flex;
      flex-wrap: wrap;
      height: max-content;
      // justify-content: space-between;
      &::after {
        display: none;
      }
      // .swiper-slide {
      //   width: 4.4rem;
      //   min-height: 4.2rem;
      //   height: auto;
      //   margin-bottom: 0.1rem;
      // }
      .re-tip {
        width: 100%;
        p {
          font-size: 0.24rem;
          font-weight: bold;
          margin-bottom: 0.13rem;
        }
        .no-result {
          height: 1.3rem;
          background-color: #f3e2e2;
          line-height: 1.3rem;
          text-align: center;
        }
      }
      .get-tip {
        display: none;
      }
    }
  }
  .one-case {
    background-color: #fff;
    width: 4.33rem;
    min-height: 4.2rem;
    margin: 0 0.17rem 0.17rem 0;
    position: relative;
    .tag {
      padding: 0 0.16rem;
      color: #eee;
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
    }
    .pic {
      height: 2.7rem;
      background: center/cover no-repeat;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .desc {
      padding: 0.2rem;
      // border: 1px dotted;
      .tit {
        font-size: 0.16rem;
        font-weight: bold;
        color: #000;
      }
      .type {
        color: #ccc;
      }
      .border {
        margin: 0.16rem 0 0.26rem;
        border-top: 1px solid #ccc;
      }
    }
  }
  .page-box {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    span {
      margin: 0 0.1rem;
      // border: 1px dotted;
      padding: 0.13rem;
      background: #ccc;
    }
  }
  @media screen and (max-width: 768px) {
    .to-top {
      width: 0.5rem;
      height: 0.5rem;
      position: fixed;
      right: 0.3rem;
      bottom: 0.3rem;
      // border: 1px solid;
    }
    .list-top {
      display: block;
      padding: 0.8rem 0.4rem;
      .title {
        font-size: 0.5rem;
        font-weight: bold;
        text-align: center;
      }
      .search-bt {
        display: flex;
        justify-content: space-between;
        margin: 0.3rem 0;
      }
      .input {
        width: 5.4rem;
        border: 0;
        height: 0.8rem;
        border-radius: 6px;
        // padding-left: 1rem;
        // background: #fff url(../assets/imgs/search.png) 0.37rem center/0.37rem
        //   no-repeat;
      }
      .bt {
        border-radius: 6px;
        padding: 0rem 0.2rem;
        color: #fff;
        font-size: 0.32rem;
        background: #d13736;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
      .pop {
        font-size: 0.25rem;
        .text {
          color: #666;
        }
        .pop-text {
          position: relative;
          padding: 0 0.33rem;
          &::before {
            content: "";
            height: 60%;
            border-right: 1px solid #ccc;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
          &:nth-of-type(2) {
            &::before {
              display: none;
            }
          }
        }
      }
    }

    .all-case-box {
      min-height: calc(100vh - 8.3rem);
      .tags-box {
        margin: 0.3rem 0 0.36rem;
        width: 100%;
        display: flex;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          background-color: #f0f0f0;
          position: relative;
          white-space: nowrap;
          margin-right: 0.3rem;
          font-size: 0.27rem;
          color: #ababab;
          padding: 0.1rem 0.3rem;
          border-radius: 0.08rem;
          &.on {
            &::after {
              content: "";
              width: 0.16rem;
              height: 0.16rem;
              background: #d13736 url(../assets/imgs/tick.png) center/0.1rem
                no-repeat;
              border-radius: 0.08rem 0 0.08rem 0;
              position: absolute;
              right: 0;
              bottom: 0;
            }
            background-color: #f3e3e3;
            color: #d13736;
          }
        }
      }

      .tabs-box {
        width: 100%;
        display: flex;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          position: relative;
          white-space: nowrap;
          margin-right: 0.3rem;
          font-size: 0.27rem;
          color: #000;
          padding: 0.1rem 0;
          &.on {
            // border-bottom: 3px solid #d13736;
            color: #d13736;
            &::after {
              border-radius: 0.03rem;
              content: "";
              height: 0.03rem;
              width: 100%;
              background: #d13736;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
      padding: 0.3rem 0.4rem;
      .left-ul,
      .page-box {
        display: none;
      }

      .case-box {
        .get-tip {
          width: 100%;
          display: block;
          text-align: center;
        }
      }

      .one-case,
      .case-box {
        margin:0 0 0.3rem;
        width: 100%;
      }
      .one-case {
        overflow: hidden;
        border-radius: 5px;
        background-color: #fff;
        position: relative;
        .tag {
          padding: 0.06rem 0.16rem;
          color: #eee;
          position: absolute;
          top: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 0 0 0 5px;
        }
        .pic {
          height: 4rem;
          // background: url(/images/dragontrailh5/pc/test.png) center/cover
          //   no-repeat;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .desc {
          padding: 0.2rem;
          // border: 1px dotted;
          .tit {
            font-size: 0.3rem;
            font-weight: bold;
            color: #000;
          }
          .type {
            color: #ccc;
            font-size: 0.22rem;
          }
          .border,
          .pos {
            display: none;
          }
        }
      }
    }
  }
</style>

module.exports = {
    case_count: '案例项目',
    case_brand: '合作品牌',
    all: '全部',
    case_country: '国家地域',
    title: '案例库',
    partner: '合作伙伴',
    rec_case: '精选案例',
    cooperation: '商务合作',
    social: '社交媒体',
    index_title: '旅游数字营销专家',
    hoted_search: '热门搜索',
    list: {
        tag_selected: '标签筛选',
        no_kd_tip: '请输入搜索关键词',
        get_more: '获取数据中...',
        no_date: '已加载全部数据！',
        search_results: '的搜索结果',
        re_input: '没有符合此关键词的案例，请重新输入或',
        go_back: '返回首页'
    },
    top: {
        index: '首页',
        all_case: '全部案例',
        placeholder: '搜索相关关键词',
        search: '搜索'
    },
    det: {
        date: '日期',
        tag: '标签',
        copy_success:'复制成功',
        intro: '项目介绍',
        share: '分享',
        copy_url: '复制链接',
        online: '在线体验',
        type: '类型',

    }
}

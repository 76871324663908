<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div class="co-bot">
    <div class="eml">
      <span class="test">{{$t('cooperation')}}</span> <span class="text"> qqq@qq.qq</span>
    </div>
    
    <div class="ept"></div>
    <div class="social">
      <span class="test tst">{{$t('social')}}</span>
      <div class="img-box"><img src="../assets/imgs/youku.png" alt="">
        <img src="../assets/imgs/weibo.png" alt="">
        <img src="../assets/imgs/wx.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
  // import HelloWorld from './components/Index.vue'

  export default {
    name: 'App',
    components: {
      // HelloWorld
    }
  }
</script>
<style lang="less" scoped>
  .co-bot {
    width: 100%;
    background: #000;
    height: 0.5rem;
    // position: fixed;
    font-size: 0.2rem;
    color: #fff; 
    // bottom: 0;
    // z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    .ept {
      width: 1.6rem;
    }
    .text{color: #ccc;}
    .test {
      display: inline-block;
      // line-height: 1;
      padding-right: 0.16rem;
      // border-right: 1px solid #ccc;
      margin-right: 0.16rem;
      text-align: center;
      position: relative;
      &::after {
        position: absolute;
        content: "|";
        top: 0;
        right: 0rem;
      }
    }

    .social {
      img {
        width: 0.3rem;
        margin-right: 0.3rem;
      }
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    .co-bot {
        font-size: 0.3rem;
      padding: 0.17rem 0.3rem 0.68rem;
      .text{padding-bottom: 0.3rem;}
      .test {
        margin: 0.53rem 0;
        &::after {
          color: #ccc;
          content: "—";
          top: 0.47rem;
          right: 50%;
          transform: translateX(50%);
        }
      &.tst::after{
        display: none;
      }
      }
      height: auto;
      flex-direction: column;
      .ept {
        display: none;
      }      .eml,
      .social {
        display: flex;
        flex-direction: column;
        .img-box {
          img{
            width: 0.5rem;margin: 0 0.67rem;
          }
        }
      }
    }
  }
</style>

(function () {
  var setHtmlRootFontSize = function () {

    // screenWidth < 1024 移动端等比缩略
    // 1024 <= screenWidth PC 端用 1920x1080 的 UI 稿等比缩放, 最小宽 1024
    if (window.innerWidth < 768) {
      document.querySelector('html').style.fontSize = window.innerWidth / 750 * 100 + 'px'
    } else {
      document.querySelector('html').style.fontSize = window.innerWidth / 1920 * 100 + 'px'
    }
  }



  
  setHtmlRootFontSize()

  // 窗口缩放就重算
  window.addEventListener('resize', setHtmlRootFontSize)
})()